export default {
  asset: (state, getters) => (name) => {
    const asset = getters.assets[name];
    return asset ? asset.path : null;
  },
  assetUrl: (state, getters) => (name) => `url(${getters.asset(name)})`,
  assets: (state) => state.game.assets,
  config: (state) => state.game.config,
  columnSize: (state) => state.columnSize,
  disabledSymbolsPositions: (state) => state.game.ui.disabledSymbolsPositions,
  game: (state) => state.game,
  gameType: (state) => (state.game ? state.game.gameType : undefined),
  isMobileMode: (state) => state.versionType === 'mobile',
  isRoundLoaded: (state) => state.round !== undefined,
  params: (state) => state.params,
  paylines: (state) => state.paylines,
  round: (state) => state.round,
  symbolSize: (state) => state.symbolSize,
  symbols: (state) => state.round.reelWindow,
  versionType: (state) => state.versionType,
  winLineSettings: (state) => state.winLineSettings,
  winlines: (state) => state.round.winLines,
};
